














































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { CarouselItem } from '@/types/cms/components';
import { handleFile } from '@/utils/cms/formhelper';
import AlertModule, { AlertType } from '@/store/modules/alert';

/**
 * CMSAdminComponentCarouselItemFormDialog component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class CMSAdminComponentCarouselItemFormDialog extends Vue {
    // Prop of type boolean that defined whether the dialog should be shown or not
    @Prop(Boolean) readonly showDialog: boolean | undefined;

    // Prop that holds the carousel item data
    @Prop({type: Object as () => CarouselItem}) readonly editedCarouselItem: CarouselItem | undefined;

    // Prop that holds the id of the edited carousel item
    @Prop(Number) readonly editedCarouselItemIndex: number | undefined;

    // An crud string that defines the mode of the dialog
    @Prop(String) readonly dialogMode: string | undefined;

    // Boolean that defines wheter the form data is valid or not
    private formValid = false;

    /**
     * @returns the title for the form
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get formTitle(): string {
        return (this.dialogMode) ? this.$t('form.titles.' + this.dialogMode + 'CarouselItem').toString() : '';
    }

    /**
     * Resets the form validation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private resetFormValidation() {
        if (this.$refs.form) {
            (this.$refs.form as Vue & { resetValidation: () => void }).resetValidation();
        }
    }

    /**
     * Saves/Updates or deletes the pageList
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async saveCarouselItem() {
        if (this.editedCarouselItem) {
            if (!this.editedCarouselItem.image.obj) {
                this.resetFormValidation();
                this.$emit('carouselItemSaved', 
                    this.dialogMode, 
                    this.editedCarouselItemIndex,
                    { 
                        ...this.editedCarouselItem,
                        image: {
                            ...this.editedCarouselItem.image,
                            src: ''
                        }
                    }
                );

                return;
            }

            handleFile(
                this.dialogMode,
                this.editedCarouselItem.image.src,
                this.editedCarouselItem.image.obj
            )
            .then(fileResponse => {
                if (
                    fileResponse.data.status !== 'success' || 
                    !('fileUrl' in fileResponse.data.data) ||
                    !this.editedCarouselItem
                ) {
                    const errorMessage = this.$t('alerts.error.' + fileResponse.data.message).toString();
                    AlertModule.showAlert({ 
                        type: AlertType.ERROR,
                        message: errorMessage 
                    });

                    return;
                }

                
                this.resetFormValidation();
                this.$emit('carouselItemSaved', 
                    this.dialogMode, 
                    this.editedCarouselItemIndex,
                    { 
                        ...this.editedCarouselItem,
                        image: {
                            ...this.editedCarouselItem.image,
                            src: fileResponse.data.data.fileUrl
                        }
                    }
                );
            })
            .catch (err => {
                console.error(err);
            })
        }
    }

    /**
     * Closes the dialog
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private closeDialog() {
        this.resetFormValidation();
        this.$emit('dialogClosed');
    }
}
